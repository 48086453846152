<p-toast position="center" key="cen"></p-toast>
<div class="grid p-fluid">
  <div class="field col-12">
    <h2>
      <b>{{ phenotypicFeature?.type?.label }} </b>
    </h2>
    <h3>
      {{ phenotypicFeature?.type?.id }}
    </h3>
  </div>
  <div class="field col-12">
    <div class="field-checkbox">
      <p-checkbox
        id="observed"
        [ngModel]="!phenotypicFeature?.excluded"
        [binary]="true"
        inputId="observed"
        (onChange)="updateExcluded($event)"
      ></p-checkbox>
      <label for="observed">{{
        !phenotypicFeature?.excluded ? "Observed" : "Excluded"
      }}</label>
    </div>
  </div>
  <div class="field col-6">
    <label for="onset"><b>Onset</b></label>
    <app-time-element
      id="onset"
      [timeElement]="phenotypicFeature?.onset"
      [ontologyNodes]="onsetsNodes"
      [useOntologyClass]="true"
      (timeElementEvent)="updateOnset($event)"
      aria-labelledby="onset-label"
    ></app-time-element>
  </div>

  <div class="field col-6">
    <label for="resolution"><b>Resolution</b></label>
    <app-time-element
      id="resolution"
      [timeElement]="phenotypicFeature?.resolution"
      (timeElementEvent)="updateResolution($event)"
      aria-labelledby="resolution-time-label"
    ></app-time-element>
  </div>

  <div class="field col-12">
    <p-accordion>
      <p-accordionTab header="Edit more details">
        <div class="grid p-fluid">
          <div class="field col-12">
            <label for="severity"><b>Severity</b></label>
            <p-dropdown
              id="severity"
              appendTo="body"
              placeholder="Select severity"
              [options]="severities"
              [(ngModel)]="selectedSeverity"
              (onChange)="updateSeverity($event)"
              optionLabel="lbl"
              scrollHeight="15rem"
              pTooltip="The intensity or degree of a manifestation"
              tooltipPosition="bottom"
              showDelay="2000"
              [showClear]="true"
            >
              <ng-template let-severity pTemplate="item">
                <div
                  [pTooltip]="severity.lbl"
                  showDelay="2000"
                  tooltipPosition="bottom"
                  escape="false"
                >
                  [{{ severity.id }}] {{ severity.lbl }}
                </div>
              </ng-template>
            </p-dropdown>
          </div>

          <div class="field col-12">
            <label for="modifier"><b>Modifiers</b></label>

            <ontology-tree-select
              *ngIf="modifiersNodesLoaded"
              id="modifier"
              pTooltip="This subontology is designed to provide terms to characterize and specify the phenotypic abnormalities defined in the Phenotypic abnormality subontology, with respect to severity, laterality, age of onset, and other aspects."
              tooltipPosition="bottom"
              showDelay="2000"
              [selectionMode]="'checkbox'"
              (selectedNodesChange)="updateModifiers($event)"
              [selectedNodes]="selectedModifierNodes"
              [nodes]="modifiersNodes"
            ></ontology-tree-select>

            <!-- <app-tree-search></app-tree-search> -->
          </div>

          <div class="field col-12">
            <label for="evidence"><b>Evidence</b></label>
            <ontology-tree-select
              *ngIf="evidenceNodesLoaded"
              id="evidence"
              pTooltip="This element intends to represent the evidence for an assertion such as an observation of a PhenotypicFeature. Terms available are from the Evidence & Conclusion Ontology (ECO)."
              tooltipPosition="bottom"
              showDelay="2000"
              (selectedNodesChange)="updateEvidences($event)"
              [selectedNodes]="phenotypicFeature?.evidenceNodes"
              [selectionMode]="'checkbox'"
              [nodes]="evidencesNodes"
            ></ontology-tree-select>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
</div>
<div class="p-dialog-footer">
  <button
    pButton
    pRipple
    type="button"
    label="Cancel"
    (click)="onCancelClick()"
  ></button>
  <button
    pButton
    pRipple
    type="button"
    label="Ok"
    (click)="onOkClick()"
  ></button>
</div>