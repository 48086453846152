<p-panel>
  <ng-template pTemplate="header">
    <span class="font-medium text-xl">{{ interpretationId }}</span>
  </ng-template>
  <div class="grid">
    <div class="field col-12"></div>
    <div class="field col-6">
      <b>Interpretation ID:</b> {{ interpretationId }}
    </div>
    <div class="field col-6"><b>Progress status:</b> {{ status }}</div>

    <div class="field col-6"><b>Disease diagnosis:</b> {{ disease }}</div>
    <div class="field col-6"></div>
    <div class="field col-12">
      <p-table
        [value]="interpretation?.diagnosis?.genomicInterpretations"
        dataKey="key"
        [tableStyle]="{ 'min-width': '60rem' }"
      >
        <ng-template pTemplate="caption">
          <div class="table-header">Genomic Interpretation(s)</div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 5rem"></th>
            <th pResizableColumn style="min-width: 2rem">Subject ID</th>
            <th pResizableColumn>Status</th>
            <th pResizableColumn>Call</th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-genomicinterpretation
          let-expanded="expanded"
        >
          <tr>
            <td>
              <button
                type="button"
                pButton
                pRipple
                [pRowToggler]="genomicinterpretation"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
              ></button>
            </td>
            <td>{{ genomicinterpretation.subjectOrBiosampleId }}</td>
            <td>{{ genomicinterpretation.interpretationStatus }}</td>
            <td>{{ getCall(genomicinterpretation) }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-genomicinterpretation>
          <tr>
            <td colspan="4">
              <div class="p-3">
                <div class="grid">
                  <div class="field col:6">
                    <b>Subject or biosample ID:</b>
                    {{ genomicinterpretation.subjectOrBiosampleID }}
                  </div>
                  <div class="field col:6">
                    <b>Interpretation Status:</b>
                    {{ genomicinterpretation.interpretationStatus }}
                  </div>
                  <div
                    *ngIf="genomicinterpretation.geneDescriptor"
                    class="field col:12"
                  >
                    <!-- TODO -->
                  </div>
                  <div
                    *ngIf="genomicinterpretation.variantInterpretation"
                    class="field col:12"
                  >
                    <div class="grid">
                      <div class="field col-6">
                        <b>Acmg pathogenicity classification:</b>
                        {{
                          genomicinterpretation.variantInterpretation
                            .acmgPathogenicityClassification
                        }}
                      </div>
                      <div class="field col-6">
                        <b>Therapeutic Actionability:</b>
                        {{
                          genomicinterpretation.variantInterpretation
                            .therapeuticActionability
                        }}
                      </div>
                      <div class="field col-6">
                        <b>Variation descriptor id:</b>
                        {{
                          genomicinterpretation.variantInterpretation
                            .variationDescriptor?.id
                        }}
                      </div>
                      <div class="field col-6">
                        <b>Variation descriptor label:</b>
                        {{
                          genomicinterpretation.variantInterpretation
                            .variationDescriptor?.label
                        }}
                      </div>
                      <div class="field col-12">
                        <b>Variation descriptor description:</b>
                        {{
                          genomicinterpretation.variantInterpretation
                            .variationDescriptor?.description
                        }}
                      </div>
                      <div class="field col-12">
                        <p-table
                          [value]="
                            genomicinterpretation.variantInterpretation
                              .variationDescriptor?.expressions
                          "
                          dataKey="key"
                          [tableStyle]="{ 'min-width': '50rem' }"
                        >
                          <ng-template pTemplate="caption">
                            <div class="table-header">Expressions</div>
                          </ng-template>
                          <ng-template pTemplate="header">
                            <tr>
                              <th>
                                Syntax
                              </th>
                              <th>
                                Value
                              </th>
                              <th>
                                Version
                              </th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-expression>
                            <tr>
                              <td>{{ expression.syntax }}</td>
                              <td>{{ expression.value }}</td>
                              <td>{{ expression.version }}</td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                      <div class="field col-12">
                        <p-table
                          [resizableColumns]="true"
                          [autoLayout]="true"
                          [value]="[
                            genomicinterpretation.variantInterpretation
                              .variationDescriptor?.vcfRecord
                          ]"
                          dataKey="key"
                          [tableStyle]="{ 'min-width': '50rem' }"
                        >
                          <ng-template pTemplate="caption">
                            <div class="table-header">VCF Record</div>
                          </ng-template>
                          <ng-template pTemplate="header">
                            <tr>
                              <th>
                                Assembly
                              </th>
                              <th>
                                Chr
                              </th>
                              <th>
                                Position
                              </th>
                              <th>
                                ID
                              </th>
                              <th>Ref</th>
                              <th>Alt</th>
                              <th>
                                Qual
                              </th>
                              <th>
                                Filter
                              </th>
                              <th>
                                Info
                              </th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-vcfrecord>
                            <tr>
                              <td>{{ vcfrecord.genomeAssembly }}</td>
                              <td>{{ vcfrecord.chrom }}</td>
                              <td>{{ vcfrecord.pos }}</td>
                              <td>{{ vcfrecord.id }}</td>
                              <td>{{ vcfrecord.ref }}</td>
                              <td>{{ vcfrecord.alt }}</td>
                              <td>{{ vcfrecord.qual }}</td>
                              <td>{{ vcfrecord.filter }}</td>
                              <td>{{ vcfrecord.info }}</td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                      <div class="field col-6">
                        <b>Molecular context:</b>
                        {{
                          genomicinterpretation.variantInterpretation.variationDescriptor?.moleculeContext
                        }}
                      </div>
                      <div class="field col-6">
                        <b>Allelic state:</b>
                        {{
                          genomicinterpretation.variantInterpretation.variationDescriptor?.allelicState?.label
                        }}
                      </div>
                      <div class="field col-6">
                        <b>Structural type:</b>
                        {{
                          genomicinterpretation.variantInterpretation.variationDescriptor?.structuralType?.toString()
                        }}
                      </div>
                      <div class="field col-6">
                        <b>VRS Ref allele sequence:</b>
                        {{
                          genomicinterpretation.variantInterpretation
                            .variationDescriptor?.vrsRefAlleleSeq
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-panel>
