<div class="flex">
  <div class="flex-initial flex align-items-center justify-content-center">
    <button
      pButton
      icon="pi pi-plus"
      label="Add file"
      class="p-button-outlined mb-2"
      (click)="addFile()"
    ></button>
  </div>
</div>

<p-table *ngIf="files?.length > 0" [value]="files" dataKey="key" styleClass="p-datatable-sm">
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th>URI</th>
      <th>Description</th>
      <th>ID mappings</th>
      <th>Attributes</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-file let-expanded="expanded">
    <tr>
      <td>
        <button
          type="button"
          pButton
          pRipple
          [pRowToggler]="file"
          class="p-button-text p-button-rounded p-button-plain"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        ></button>
      </td>
      <td>{{ file.uri }}</td>
      <td><span *ngIf="file.fileAttributes">{{ file.fileAttributes["description"] }}</span></td>
      <td>
        <div class="card flex align-items-center gap-2 flex-wrap">
          <span *ngFor="let item of file.individualToFileIdentifiers | keyvalue">
            <p-chip [label]="getKeyValueLabel(item)"></p-chip>
          </span>
        </div>
      </td>
      <td>
        <div class="card flex align-items-center gap-2 flex-wrap">
          <span *ngFor="let attribute of file.fileAttributes | keyvalue">
            <span *ngIf="attribute.key !== 'description'">
              <p-chip
              [label]="getKeyValueLabel(attribute)"
              [styleClass]="getColor(attribute)"
            ></p-chip>
            </span>
            
          </span>
        </div>
      </td>

      <td>
        <button
          pButton
          pRipple
          icon="pi pi-pencil"
          class="p-button-rounded p-button-text"
          (click)="editFile(file)"
        ></button>
        <button
          pButton
          pRipple
          icon="pi pi-trash"
          class="p-button-rounded p-button-text p-button-warning"
          (click)="removeFile(file)"
        ></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-file>
    <tr>
      <td colspan="6">
        <div class="p-3">
          <app-file-detail
            [file]="file"
          >
          </app-file-detail>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="6">No file to display.</td>
    </tr>
  </ng-template>
</p-table>
