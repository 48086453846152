<div class="grid p-fluid word">
  <div class="field col-12 md:col-6"><b>Id:</b> {{ phenotypicFeature?.type?.id }}</div>
  <div class="field col-12 md:col-6">
    <b>Name:</b>
    {{ phenotypicFeature?.type?.label }}
  </div>
  <div class="field col-12">
    <div class="field-checkbox">
      <p-checkbox
        ngDefaultControl
        id="observed"
        [ngModel]="!phenotypicFeature?.excluded"
        [binary]="true"
        inputId="observed"
        (onChange)="updateExcluded($event)"
      ></p-checkbox>
      <label for="observed">{{
        !phenotypicFeature?.excluded ? "Observed" : "Excluded"
      }}</label>
    </div>
  </div>
</div>
<div class="p-dialog-footer">
  <button
    pButton
    pRipple
    type="button"
    label="Reject"
    (click)="reject()"
  ></button>
  <button
    pButton
    pRipple
    type="button"
    label="Approve"
    (click)="approve()"
  ></button>
</div>
