<p-toast position="center" key="cen"></p-toast>
<p-confirmDialog
  position="center"
  [style]="{ width: '50vw' }"
></p-confirmDialog>

<div class="grid p-fluid">
  <div class="field col-12 md:col-6">
    <label for="interpretationid">Interpretation ID *</label>
    <div class="p-inputgroup">
      <input
        id="interpretationid"
        type="text"
        aria-describedby="Interpretation ID"
        required
        pInputText
        [(ngModel)]="id"
        [ngClass]="{
          'ng-dirty': !id && submitted
        }"
      />
      <button
        pButton
        icon="pi pi-replay"
        label="Generate ID"
        (click)="generateNewID()"
      ></button>
    </div>
    <!-- (ngModelChange)="onIdChange($event)" -->
    <small *ngIf="!id && submitted" class="p-error"
      >Interpretation ID is required.</small
    >
  </div>
  <div class="field col-12 md:col-6">
    <label for="progressstatus">Progress status *</label>
    <p-dropdown
      id="progressstatus"
      appendTo="body"
      placeholder="Select progress status"
      [(ngModel)]="selectedProgressStatus"
      [options]="progressStatuses"
      (onChange)="updateProgressStatus($event)"
      pTooltip="Progress status"
      tooltipPosition="bottom"
      scrollHeight="15rem"
      showDelay="2000"
      required
      class="w-full"
      showClear="true"
      [ngClass]="{
        'ng-dirty': !selectedProgressStatus && submitted
      }"
    ></p-dropdown>
    <small *ngIf="!selectedProgressStatus && submitted" class="p-error"
      >Progress status is required.</small
    >
  </div>
  <!-- <div class="field col-12 md:col-12">
    <span class="p-float-label">
      <textarea
        id="summary"
        rows="5"
        cols="30"
        pInputTextarea
        (ngModelChange)="onSummaryChange($event)"
      ></textarea>
      <label for="Summary">Summary</label>
    </span>
  </div> -->
  <div class="field col-12">
    <p-card
      header="Diagnosis"
      subheader="Create a diagnosis with its corresponding disease and genomic interpretation."
    >
      <div class="grid p-fluid">
        <div class="field col-12 md:col-6">
          <label for="disease">Disease *</label>
          <p-dropdown
            id="disease"
            placeholder="Search for a disease"
            appendTo="body"
            [options]="diseaseItems"
            [(ngModel)]="selectedDisease"
            filter="true"
            filterBy="id,label"
            (onFilter)="diseaseContentChanging($event.filter)"
            (onChange)="updateDiseaseItemSelection($event.value)"
            showClear="true"
            required
            [ngClass]="{
              'ng-dirty': !selectedDisease && submitted
            }"
          >
            <ng-template let-disease pTemplate="item">
              <div
                [pTooltip]="disease.label"
                showDelay="1000"
                tooltipPosition="left"
                escape="false"
              >
                [{{ disease.id }}] {{ disease.label }}
              </div>
            </ng-template>
          </p-dropdown>
          <small *ngIf="!selectedDisease && submitted" class="p-error"
            >Disease diagnosis is required.</small
          >
        </div>

        <div class="field col-12 md:col-6"></div>

        <div class="field col-12">
          <div class="flex">
            <div
              class="flex-initial flex align-items-center justify-content-center"
            >
              <button
                pButton
                icon="pi pi-plus"
                label="Add genomic interpretation"
                class="p-button-outlined mb-2"
                (click)="addGenomicInterpretation()"
              ></button>
            </div>
          </div>
          <p-table
            *ngIf="genomicInterpretations?.length > 0"
            [value]="genomicInterpretations"
            dataKey="key"
            styleClass="p-datatable-sm"
          >
            <ng-template pTemplate="header">
              <tr>
                <!-- <th pResizableColumn style="min-width: 2rem">Subject ID</th> -->
                <th pResizableColumn>Status</th>
                <th pResizableColumn>Call</th>
                <th pResizableColumn></th>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-genomicinterpretation
              let-expanded="expanded"
            >
              <tr>
                <!-- <td>{{ genomicinterpretation.subjectOrBiosampleId }}</td> -->
                <td>{{ genomicinterpretation.interpretationStatus }}</td>
                <td>
                  <span
                    *ngIf="
                      getCall(genomicinterpretation) === 'VariantInterpretation'
                    "
                  >
                    <!-- If VariantInterpretation -->
                    <div class="grid p-fluid">
                      <div class="field col-12 md:col-6">
                        <i>Annotations:</i>
                        <ul>
                          <li
                            *ngFor="
                              let annotation of genomicinterpretation
                                ?.variantInterpretation?.variationDescriptor
                                ?.expressions
                            "
                          >
                            {{ annotation.value }}
                          </li>
                        </ul>
                      </div>
                      <div class="field col-12 md:col-6">
                        <i>Allelic state:</i>
                        <ul>
                          <li>
                            {{
                              genomicinterpretation?.variantInterpretation
                                ?.variationDescriptor?.allelicState?.label
                            }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </span>
                  <span
                    *ngIf="getCall(genomicinterpretation) === 'GeneDescriptor'"
                  >
                    <!-- TODO -->
                  </span>
                </td>
                <td>
                  <button
                    pButton
                    pRipple
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-text"
                    (click)="editGenomicInterpretation(genomicinterpretation)"
                  ></button>
                  <button
                    pButton
                    pRipple
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-text p-button-warning"
                    (click)="deleteGenomicInterpretation(genomicinterpretation)"
                  ></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colspan]="4">
                  No genomic interpretation to display.
                </td>
              </tr>

              <tr></tr
            ></ng-template>
          </p-table>
        </div>
      </div>
    </p-card>
  </div>
  <!-- <div class="field col-12">
    <div class="absolute right-0 mr-4">
      <button
        pButton
        label="Add Interpretation"
        (click)="updateInterpretation()"
        style="width: 300px"
      ></button>
    </div>
  </div> -->
</div>
<div class="p-dialog-footer">
  <button
    pButton
    pRipple
    type="button"
    label="Cancel"
    (click)="onCancelClick()"
  ></button>
  <button
    pButton
    pRipple
    type="button"
    [label]="okButtonLabel"
    (click)="onOkClick()"
  ></button>
</div>
