<p-toast position="center" key="cen"></p-toast>
<div class="m-5">
  <h1 class="header-step"><b>Interpretation(s)</b></h1>
  <app-interpretation
    [interpretations]="interpretations"
    [phenopacket]="phenopacket"
    [profile]="profileSelection"
    (onInterpretationsChange)="updateInterpretations($event)"
  ></app-interpretation>
</div>
