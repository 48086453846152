<p-toast position="center" key="cen"></p-toast>
<div class="grid p-fluid">
  <div class="field col-12">
    <h2>
      <b>{{ disease?.term?.label }} </b>
    </h2>
    <h3>
      {{ disease?.term?.id }}
    </h3>
  </div>
  <div class="fiewl col-12">
    <div class="field-checkbox">
      <p-checkbox
        ngDefaultControl
        id="observed"
        [ngModel]="!disease?.excluded"
        [binary]="true"
        inputId="observed"
        (onChange)="updateExcluded($event)"
      ></p-checkbox>
      <label for="observed">{{
        !disease?.excluded ? "Diagnosed" : "Excluded"
      }}</label>
    </div>
  </div>

  <div class="field col-6">
    <label for="onset"><b>Onset</b></label>
    <app-time-element
      id="onset"
      [timeElement]="disease?.onset"
      [ontologyNodes]="onsetsNodes"
      [useOntologyClass]="true"
      (timeElementEvent)="updateOnset($event)"
      aria-labelledby="onset-label"
    ></app-time-element>
  </div>
  <br />
  <div class="field col-6">
    <label for="resolution"><b>Resolution:</b></label>
    <app-time-element
      id="resolution"
      [timeElement]="disease?.resolution"
      (timeElementEvent)="updateResolution($event)"
      aria-labelledby="resolution-time-label"
    ></app-time-element>
  </div>
  <div *ngIf="!isRareProfile()" class="field col-12">
    <p-accordion>
      <p-accordionTab header="Edit more details">
        <div class="grid p-fluid">
          <div class="field col-12">
            <label for="stages"><b>Disease stage:</b></label>
            <p-treeSelect
              ngDefaultControl
              id="stages"
              appendTo="body"
              [(ngModel)]="stageSelected"
              [options]="diseaseStagesNodes"
              (onNodeSelect)="updateDiseaseStage($event)"
              [metaKeySelection]="false"
              placeholder="Select Disease Stage"
              [filter]="true"
              filterBy="label,key"
              [filterInputAutoFocus]="true"
              [showClear]="true"
              (onClear)="updateDiseaseStage($event)"
            >
              <ng-template let-node pTemplate="default">
                <span
                  [pTooltip]="node.label"
                  showDelay="2000"
                  tooltipPosition="bottom"
                  >[{{ node.key }}] {{ node.label }}</span
                >
              </ng-template>
            </p-treeSelect>
          </div>
          <div class="field col-12">
            <label for="laterality"><b>Laterality:</b></label>
            <p-dropdown
              ngDefaultControl
              id="laterality"
              appendTo="body"
              [options]="lateralities"
              [(ngModel)]="lateralitySelected"
              (onChange)="updateLaterality($event)"
              optionLabel="lbl"
              [filter]="true"
              filterBy="lbl,id"
              [showClear]="true"
              placeholder="Select a laterality"
            >
              <ng-template let-laterality pTemplate="item">
                <div
                  [pTooltip]="laterality.lbl"
                  showDelay="2000"
                  tooltipPosition="bottom"
                  escape="false"
                >
                  [{{ laterality.id }}] {{ laterality.lbl }}
                </div>
              </ng-template>
            </p-dropdown>
          </div>

          <div class="field col-12">
            <label for="findings"><b>Clinical TNM finding:</b></label>
            <p-treeSelect
              ngDefaultControl
              id="findings"
              appendTo="body"
              [(ngModel)]="tumorSelected"
              [options]="tumorNodes"
              (onNodeSelect)="updateTnmStages($event, 'tumor')"
              [metaKeySelection]="false"
              placeholder="Select tumor stage"
              [filter]="true"
              filterBy="label,key"
              [filterInputAutoFocus]="true"
              [showClear]="true"
              (onClear)="updateTnmStages($event, 'tumor')"
            >
              <ng-template let-node pTemplate="default">
                <span
                  [pTooltip]="node.label"
                  showDelay="2000"
                  tooltipPosition="bottom"
                  escape="false"
                  >[{{ node.key }}] {{ node.label }}</span
                >
              </ng-template>
            </p-treeSelect>
            <p-treeSelect
              ngDefaultControl
              id="findings"
              appendTo="body"
              [(ngModel)]="nodeSelected"
              [options]="nodeNodes"
              (onNodeSelect)="updateTnmStages($event, 'node')"
              [metaKeySelection]="false"
              placeholder="Select node stage"
              [filter]="true"
              filterBy="label,key"
              [filterInputAutoFocus]="true"
              [showClear]="true"
              (onClear)="updateTnmStages($event, 'node')"
            >
              <ng-template let-node pTemplate="default">
                <span
                  [pTooltip]="node.label"
                  showDelay="2000"
                  tooltipPosition="bottom"
                  escape="false"
                  >[{{ node.key }}] {{ node.label }}</span
                >
              </ng-template>
            </p-treeSelect>
            <p-treeSelect
              ngDefaultControl
              id="findings"
              appendTo="body"
              [(ngModel)]="metastasisSelected"
              [options]="metastasisNodes"
              (onNodeSelect)="updateTnmStages($event, 'metastasis')"
              [metaKeySelection]="false"
              placeholder="Select metastasis stage"
              [filter]="true"
              filterBy="label,key"
              [filterInputAutoFocus]="true"
              [showClear]="true"
              (onClear)="updateTnmStages($event, 'metastasis')"
            >
              <ng-template let-node pTemplate="default">
                <span
                  [pTooltip]="node.label"
                  showDelay="2000"
                  tooltipPosition="bottom"
                  escape="false"
                  >[{{ node.key }}] {{ node.label }}</span
                >
              </ng-template>
            </p-treeSelect>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
</div>
<div class="p-dialog-footer">
  <button
    pButton
    pRipple
    type="button"
    label="Cancel"
    (click)="onCancelClick()"
  ></button>
  <button
    pButton
    pRipple
    type="button"
    label="Ok"
    (click)="onOkClick()"
  ></button>
</div>
