<p-panel>
  <ng-template pTemplate="header">
    <span *ngIf="actionType === 'Procedure'" class="font-medium text-xl">{{
      procedureCode?.toString()
    }}</span>
    <span *ngIf="actionType === 'Treatment'" class="font-medium text-xl">{{
      agent?.toString()
    }}</span>

    <span
      *ngIf="actionType === 'Radiation therapy'"
      class="font-medium text-xl"
      >{{ modality?.toString() }}</span
    >
    <span
      *ngIf="actionType === 'Therapeutic regimen'"
      class="font-medium text-xl"
      >{{ getIdentifier() }}</span
    >
  </ng-template>
  <div class="grid">
    <div class="field col-6">
      <b>Treatment target: </b>{{ treatmentTarget?.label }}
    </div>
    <div class="field col-6">
      <b>Treatment intent: </b>{{ treatmentIntent?.label }}
    </div>
    <div class="field col-6">
      <b>Response to treatment: </b>{{ responseToTreatment?.label }}
    </div>
    <div class="field col-6">
      <b>Treatment termination reason: </b>{{ terminationReason?.label }}
    </div>
    <div class="field col-12">
      <p-table
        *ngIf="medicalAction?.adverseEvents?.length > 0"
        [value]="medicalAction?.adverseEvents"
        dataKey="key"
        styleClass="p-datatable-sm"
      >
        <ng-template pTemplate="caption">
          <div class="table-header">Adverse events</div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>ID</th>
            <th>Label</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-event>
          <tr>
            <td><a href="{{ event.termUrl }}" target="_blank">{{ event.id }}</a></td>
            <td>{{ event.label }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <!-- Procedure medical action -->
    <div *ngIf="actionType === 'Procedure'" class="field col-12">
      <div class="grid6">
        <div class="field col-6">
          <b>Code: </b>{{ procedureCode?.toString() }}
        </div>
        <div class="field col-6">
          <b>Body sites: </b>{{ bodySite?.toString() }}
        </div>
        <div class="field col-12">
          <b>Performed on: </b>{{ performed?.toString() }}
        </div>
      </div>
    </div>
    <!-- Treatement medical action -->
    <div *ngIf="actionType === 'Treatment'" class="field col-12">
      <div class="grid">
        <div class="field col-4"><b>Agent: </b>{{ agent?.toString() }}</div>
        <div class="field col-4">
          <b>Route of administration: </b
          >{{ routeOfAdministration?.toString() }}
        </div>
        <div class="field col-4"><b>Drug type: </b>{{ drugType }}</div>
        <div class="field col-12">
          <p-table *ngIf="doseIntervals?.length > 0" [value]="doseIntervals" styleClass="p-datatable-sm">
            <ng-template pTemplate="caption"> Dose intervals </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>Unit</th>
                <th>Value</th>
                <th>Schedule frequency</th>
                <th>Interval</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-interval>
              <tr>
                <td>{{ interval.quantity?.unit?.label }}</td>
                <td>{{ interval.quantity?.value }}</td>
                <td>{{ interval.scheduleFrequency?.label }}</td>
                <td>
                  {{ interval.interval?.start }} - {{ interval.interval?.end }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="field col-12">
          <b>Cumulative dose: </b>
          {{ cumulativeDose?.value }} {{ cumulativeDose?.unit?.label }}
        </div>
      </div>
    </div>
    <!-- RadiationTherapy medical action -->
    <div *ngIf="actionType === 'Radiation therapy'" class="field col-12">
      <div class="grid4">
        <div class="field col-6">
          <b>Modality: </b>[{{ modality?.id }}] {{ modality?.label }}
        </div>
        <div class="field col-6">
          <b>Body site: </b>{{ bodySite?.toString() }}
        </div>
        <div class="field col-6">
          <b>Dosage: </b><span *ngIf="dosage">{{ dosage }} Gy</span>
        </div>
        <div class="field col-6"><b>Fractions: </b>{{ fractions }}</div>
      </div>
    </div>
    <!-- TherapeuticRegimen medical action -->
    <div *ngIf="actionType === 'Therapeutic regimen'" class="field col-12">
      <div class="grid p-fluid">
        <div class="field col-4">
          <b>Identifier</b>
          <br />
          {{ getIdentifier() }}
        </div>
        <div class="field col-4">
          <b>Start</b>
          <br />
          {{ startTime }}
        </div>
        <div class="field col-4">
          <b>End</b>
          <br />
          {{ endTime }}
        </div>
        <div class="field col-12">
          <b>Regimen status: </b>{{ regimenStatus }}
        </div>
      </div>
    </div>
  </div>
</p-panel>
