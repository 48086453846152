<p-toast position="center" key="cen"></p-toast>
<div class="grid p-fluid">
  <div class="field col-6">
    <label for="treatmenttarget"><b>Treatment target:</b></label>
    <p-dropdown
      ngDefaultControl
      id="treatmenttarget"
      appendTo="body"
      [options]="treatmentTargets"
      [ngModel]="treatmentTargetSelected"
      (onChange)="updateTreatmentTarget($event.value)"
      optionLabel="label"
      [filter]="true"
      filterBy="label,id"
      [showClear]="true"
      placeholder="Select a target"
    >
      <ng-template let-target pTemplate="item">
        <div
          [pTooltip]="target.label"
          showDelay="2000"
          tooltipPosition="bottom"
          escape="false"
        >
          [{{ target.id }}] {{ target.label }}
        </div>
      </ng-template>
    </p-dropdown>
  </div>
  <div class="field col-6">
    <label for="treatmentintent"><b>Treatment intent:</b></label>
    <p-dropdown
      ngDefaultControl
      id="treatmentintent"
      appendTo="body"
      [options]="treatmentIntents"
      [(ngModel)]="treatmentIntentSelected"
      (onChange)="updateTreatmentIntent($event.value)"
      optionLabel="lbl"
      [filter]="true"
      filterBy="lbl,id"
      [showClear]="true"
      placeholder="Select an intent"
    >
      <ng-template let-intent pTemplate="item">
        <div
          [pTooltip]="intent.def"
          showDelay="2000"
          tooltipPosition="bottom"
          escape="false"
        >
          [{{ intent.id }}] {{ intent.lbl }}
        </div>
      </ng-template>
    </p-dropdown>
  </div>

  <div class="field col-6">
    <label for="treatmentresponse"><b>Response to treatment:</b></label>
    <p-treeSelect
      ngDefaultControl
      id="treatmentresponse"
      appendTo="body"
      [(ngModel)]="responseToTreatmentSelected"
      [options]="responsesToTreatmentNodes"
      (onNodeSelect)="updateTreatmentResponse($event)"
      [metaKeySelection]="false"
      placeholder="Select response to treatment"
      [filter]="true"
      filterBy="key,label"
      [filterInputAutoFocus]="true"
      [showClear]="true"
      (onClear)="updateTreatmentResponse($event)"
    >
      <ng-template let-node pTemplate="default">
        <span [pTooltip]="node.label" showDelay="2000" tooltipPosition="bottom"
          >[{{ node.key }}] {{ node.label }}</span
        >
      </ng-template>
    </p-treeSelect>
  </div>

  <div class="field col-6">
    <label for="treatmenttermination"
      ><b>Treatment termination reason:</b></label
    >
    <p-treeSelect
      ngDefaultControl
      id="treatmenttermination"
      appendTo="body"
      [(ngModel)]="terminationReasonSelected"
      [options]="terminationReasonsNodes"
      (onNodeSelect)="updateTreatmentTerminationReason($event)"
      [metaKeySelection]="false"
      placeholder="Select termination reason"
      [filter]="true"
      filterBy="key,label"
      [filterInputAutoFocus]="true"
      [showClear]="true"
      (onClear)="updateTreatmentTerminationReason($event)"
    >
      <ng-template let-node pTemplate="default">
        <span [pTooltip]="node.label" showDelay="2000" tooltipPosition="bottom"
          >[{{ node.key }}] {{ node.label }}</span
        >
      </ng-template>
    </p-treeSelect>
  </div>
  <div class="field col-12">
    <p-panel header="Adverse event(s)" [toggleable]="true" [collapsed]="true">
      <ontology-tree-select
        *ngIf="adverseEventNodesLoaded"
        id="events"
        pTooltip="Adverse event ontology (OAE)"
        tooltipPosition="bottom"
        showDelay="2000"
        [selectionMode]="'checkbox'"
        (selectedNodesChange)="updateAdverseEvents($event)"
        [selectedNodes]="medicalAction?.adverseEventNodes"
        [nodes]="adverseEventNodes"
      ></ontology-tree-select>
    </p-panel>
  </div>

  <div class="field col-6">
    <label for="actiontype"><b>Select an action type:</b></label>
    <p-dropdown
      ngDefaultControl
      id="actiontype"
      appendTo="body"
      [options]="actionTypes"
      [(ngModel)]="actionType"
      (onChange)="updateActionType($event)"
      [showClear]="true"
      required
      placeholder="Select an action type"
    >
    </p-dropdown>
  </div>
  <div class="field col-6"></div>
  <!-- Procedure medical action -->
  <div *ngIf="actionType === 'Procedure'" class="field col-12">
    <div class="grid">
      <div class="field col-6">
        <label for="code"><b>Code:</b></label>
        <p-treeSelect
          ngDefaultControl
          class="${procedureSelected ? '' : 'ng-invalid ng-dirty'}"
          id="code"
          appendTo="body"
          [(ngModel)]="procedureSelected"
          [options]="proceduresNodes"
          (onNodeSelect)="updateProcedureCode($event)"
          [metaKeySelection]="false"
          placeholder="Select procedure code"
          [filter]="true"
          filterBy="key,label"
          [filterInputAutoFocus]="true"
          [showClear]="true"
          (onClear)="updateProcedureCode($event)"
        >
          <ng-template let-node pTemplate="default">
            <span
              [pTooltip]="node.label"
              showDelay="2000"
              tooltipPosition="bottom"
              >[{{ node.key }}] {{ node.label }}</span
            >
          </ng-template>
        </p-treeSelect>
      </div>
      <div class="field col-6">
        <label for="procedureBodySite"><b>Body site:</b></label>
        <p-treeSelect
          ngDefaultControl
          id="procedureBodySite"
          appendTo="body"
          [(ngModel)]="procedureBodySiteSelected"
          [options]="bodySiteNodes"
          (onNodeSelect)="updateProcedureBodySite($event)"
          [metaKeySelection]="false"
          placeholder="Select body site"
          [filter]="true"
          filterBy="label"
          [filterInputAutoFocus]="true"
          [showClear]="true"
          (onClear)="updateProcedureBodySite($event)"
        >
          <ng-template let-node pTemplate="default">
            <span
              [pTooltip]="node.label"
              showDelay="2000"
              tooltipPosition="bottom"
              >[{{ node.key }}] {{ node.label }}</span
            >
          </ng-template>
        </p-treeSelect>
      </div>
      <div class="field col-12">
        <label for="performedOn"><b>Performed on: </b> </label>
        <app-time-element
          id="performedOn"
          [timeElement]="medicalAction?.procedure?.performed"
          [ontologyNodes]="ontologyClassTimeNodes"
          [useOntologyClass]="true"
          (timeElementEvent)="updateProcedurePerformedOn($event)"
        ></app-time-element>
      </div>
    </div>
  </div>
  <!-- Treatement medical action -->
  <div *ngIf="actionType === 'Treatment'" class="field col-12">
    <div class="grid">
      <div class="field col-6">
        <label for="chemi"><b>Agent:</b></label>
        <p-dropdown
          id="chemi"
          placeholder="Search for a chemical entity"
          appendTo="body"
          [options]="chemicalEntityItems"
          [(ngModel)]="selectedChemicalEntity"
          filter="true"
          filterBy="id,label"
          required
          (onFilter)="chemicalEntityContentChanging($event.filter)"
          (onChange)="updateChemicalEntity($event.value)"
          showClear="true"
        >
          <ng-template let-chem pTemplate="item">
            <div
              [pTooltip]="chem.label"
              showDelay="1000"
              tooltipPosition="left"
              escape="false"
            >
              [{{ chem.id }}] {{ chem.label }}
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="field col-6">
        <label for="routeOfAdministration"
          ><b>Route of administration:</b></label
        >
        <p-treeSelect
          ngDefaultControl
          id="routeOfAdministration"
          appendTo="body"
          [(ngModel)]="routeOfAdministrationSelected"
          [options]="routeOfAdministrationNodes"
          (onNodeSelect)="updateRouteOfAdministration($event)"
          [metaKeySelection]="false"
          placeholder="Select route of administration"
          [filter]="true"
          filterBy="label"
          [filterInputAutoFocus]="true"
          [showClear]="true"
          (onClear)="updateRouteOfAdministration($event)"
        >
          <ng-template let-node pTemplate="default">
            <span
              [pTooltip]="node.label"
              showDelay="2000"
              tooltipPosition="bottom"
              >[{{ node.key }}] {{ node.label }}</span
            >
          </ng-template>
        </p-treeSelect>
      </div>
      <div class="field col-6">
        <label for="drugtype"><b>Drug type:</b></label>
        <p-dropdown
          ngDefaultControl
          id="drugtype"
          appendTo="body"
          [options]="drugTypes"
          [ngModel]="drugType"
          (onChange)="onDrugTypeChange($event)"
          [showClear]="true"
          placeholder="Select an drug type"
        >
        </p-dropdown>
      </div>
      <div class="field col-6"></div>

      <div class="field col-12">
        <p-table
          [value]="doseIntervals"
          dataKey="key"
          editMode="row"
          styleClass="p-datatable-sm"
        >
          <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
              Dose interval(s)
              <p-button
                icon="pi pi-plus"
                pTooltip="Add dose interval"
                tooltipPosition="bottom"
                (onClick)="addDoseInterval()"
              ></p-button>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th>Quantity</th>
              <th>Schedule frequency</th>
              <th>Interval</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-doseinterval
            let-editing="editing"
            let-ri="rowIndex"
          >
            <tr>
              <td>
                <app-quantity
                  [quantity]="doseinterval?.quantity"
                  (quantityChange)="
                    updateDoseIntervalQuantity($event, doseinterval)
                  "
                ></app-quantity>
              </td>
              <td>
                <div class="grid">
                  <div class="col-12">
                    <p-treeSelect
                      ngDefaultControl
                      id="scheduleFrequency"
                      appendTo="body"
                      [ngModel]="doseinterval.scheduleFrequency"
                      [options]="scheduleFrequencyNodes"
                      (onNodeSelect)="
                        updateScheduleFrequency($event, doseinterval)
                      "
                      [metaKeySelection]="false"
                      placeholder="Select schedule frequency"
                      [filter]="true"
                      filterBy="label"
                      [filterInputAutoFocus]="true"
                      [showClear]="true"
                      (onClear)="updateScheduleFrequency($event, doseinterval)"
                    >
                      <ng-template let-node pTemplate="default">
                        <span
                          [pTooltip]="node.label"
                          showDelay="2000"
                          tooltipPosition="bottom"
                          >[{{ node.key }}] {{ node.label }}</span
                        >
                      </ng-template>
                    </p-treeSelect>
                  </div>
                </div>
              </td>
              <td>
                <app-time-interval
                  [timeInterval]="doseinterval.interval"
                  (timeIntervalChange)="
                    updateDoseIntervalTimeInterval($event, doseinterval)
                  "
                ></app-time-interval>
              </td>
              <td>
                <button
                  pButton
                  pRipple
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-text p-button-warning"
                  (click)="deleteDoseInterval(doseinterval)"
                ></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div class="field col-12">
        <label for="cumulativeDose"><b>Cumulative dose: </b></label>
        <app-quantity
          id="cumulativeDose"
          [quantity]="medicalAction?.treatment?.cumulativeDose"
          (quantityChange)="updateCumulativeDoseQuantity($event)"
        ></app-quantity>
      </div>
    </div>
  </div>
  <!-- RadiationTherapy medical action -->
  <div *ngIf="actionType === 'Radiation therapy'" class="field col-12">
    <div class="grid">
      <div class="field col-6">
        <label for="modality"><b>Modality:</b></label>
        <p-treeSelect
          ngDefaultControl
          id="modality"
          appendTo="body"
          [(ngModel)]="radiationTherapyModalitySelected"
          [options]="radiationTherapyModalityNodes"
          (onNodeSelect)="updateRadiationTherapyModality($event)"
          [metaKeySelection]="false"
          placeholder="Select radiation therapy modality"
          [filter]="true"
          filterBy="key,label"
          required
          [filterInputAutoFocus]="true"
          [showClear]="true"
          (onClear)="updateRadiationTherapyModality($event)"
        >
          <ng-template let-node pTemplate="default">
            <span
              [pTooltip]="node.label"
              showDelay="2000"
              tooltipPosition="bottom"
              >[{{ node.key }}] {{ node.label }}</span
            >
          </ng-template>
        </p-treeSelect>
      </div>
      <div class="field col-6">
        <label for="radiationBodySite"><b>Body site:</b></label>
        <p-treeSelect
          ngDefaultControl
          id="radiationBodySite"
          appendTo="body"
          [(ngModel)]="radiationTherapyBodySiteSelected"
          [options]="bodySiteNodes"
          required
          (onNodeSelect)="updateRadiationTherapyBodySite($event)"
          [metaKeySelection]="false"
          placeholder="Select body site"
          [filter]="true"
          filterBy="label"
          [filterInputAutoFocus]="true"
          [showClear]="true"
          (onClear)="updateRadiationTherapyBodySite($event)"
        >
          <ng-template let-node pTemplate="default">
            <span
              [pTooltip]="node.label"
              showDelay="2000"
              tooltipPosition="bottom"
              >[{{ node.key }}] {{ node.label }}</span
            >
          </ng-template>
        </p-treeSelect>
      </div>
      <div class="field col-6">
        <label for="dosage"><b>Dosage:</b></label>
        <p-inputNumber
          id="dosage"
          required
          [ngModel]="medicalAction?.radiationTherapy?.dosage"
          (onInput)="updateDosage($event.value)"
          placeholder="Dosage in Gy"
        >
        </p-inputNumber>
      </div>
      <div class="field col-6">
        <label for="fractions"><b>Fractions:</b></label>
        <p-inputNumber
          id="fractions"
          required
          [ngModel]="medicalAction?.radiationTherapy?.fractions"
          (onInput)="updateFractions($event.value)"
          placeholder="Fractions"
        >
        </p-inputNumber>
      </div>
    </div>
  </div>

  <!-- TherapeuticRegimen medical action -->
  <div *ngIf="actionType === 'Therapeutic regimen'" class="field col-12">
    <div class="grid">
      <div class="field col-6">
        <label for="identifier"><b>Identifier:</b></label>
        <p-treeSelect
          ngDefaultControl
          id="identifier"
          appendTo="body"
          [(ngModel)]="therapeuticRegimenIdentifierSelected"
          [options]="therapeuticRegimenIdentifiersNodes"
          (onNodeSelect)="updateTherapeuticRegimenIdentifier($event)"
          [metaKeySelection]="false"
          placeholder="Select therapeutic regimen identifier"
          [filter]="true"
          filterBy="key,label"
          required
          [filterInputAutoFocus]="true"
          [showClear]="true"
          (onClear)="updateTherapeuticRegimenIdentifier($event)"
        >
          <ng-template let-node pTemplate="default">
            <span
              [pTooltip]="node.label"
              showDelay="2000"
              tooltipPosition="bottom"
              >[{{ node.key }}] {{ node.label }}</span
            >
          </ng-template>
        </p-treeSelect>
      </div>
      <div class="field col-6">
        <label for="regimenstatus"><b>Regimen status:</b></label>
        <p-dropdown
          ngDefaultControl
          id="regimenstatus"
          appendTo="body"
          [options]="regimenStatuses"
          [ngModel]="regimenStatus"
          (onChange)="onRegimenStatusChange($event)"
          [showClear]="true"
          [filter]="true"
          required
          placeholder="Select an regimen status"
        >
        </p-dropdown>
      </div>
      <div class="field col-6">
        <label for="starttime"><b>Start time:</b></label>
        <app-time-element
          id="starttime"
          [timeElement]="medicalAction?.therapeuticRegimen?.startTime"
          [ontologyNodes]="ontologyClassTimeNodes"
          [useOntologyClass]="true"
          (timeElementEvent)="updateStartTime($event)"
        ></app-time-element>
      </div>
      <div class="field col-6">
        <label for="endtime"><b>End time:</b></label>
        <app-time-element
          id="endtime"
          [timeElement]="medicalAction?.therapeuticRegimen?.endTime"
          [ontologyNodes]="ontologyClassTimeNodes"
          [useOntologyClass]="true"
          (timeElementEvent)="updateEndTime($event)"
        ></app-time-element>
      </div>
    </div>
  </div>
</div>

<div class="p-dialog-footer">
  <button
    pButton
    pRipple
    type="button"
    label="Cancel"
    (click)="onCancelClick()"
  ></button>
  <button
    pButton
    pRipple
    type="button"
    [label]="okLabel"
    (click)="onOkClick()"
  ></button>
</div>
