<div class="flex">
  <div class="flex-initial flex align-items-center justify-content-center">
    <button
      pButton
      icon="pi pi-plus"
      label="Add disease"
      class="p-button-outlined mb-2"
      (click)="addDisease()"
    ></button>
  </div>
</div>

<p-table
  *ngIf="diseases?.length > 0"
  [value]="diseases"
  dataKey="key"
  styleClass="p-datatable-sm"
>
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th>Disease Identifier</th>
      <th>Disease Name</th>
      <th>Status</th>
      <th>Onset</th>
      <th>Resolution</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-disease let-expanded="expanded">
    <tr>
      <td>
        <button
          type="button"
          pButton
          pRipple
          [pRowToggler]="disease"
          class="p-button-text p-button-rounded p-button-plain"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        ></button>
      </td>
      <td>
        <a
          *ngIf="disease.term"
          href="{{ getDiseaseURL(disease.term?.id) }}"
          target="_blank"
          >{{ disease.term?.id }}</a
        >
      </td>
      <td>{{ disease.term?.label }}</td>
      <td>{{ disease.excluded ? "Excluded" : "Diagnosed" }}</td>
      <td>
        <span *ngIf="disease.onset?.ontologyClass"
          ><a [href]="disease.onset.ontologyClass.termUrl" target="_blank"
            >[{{ disease.onset.ontologyClass.id }}]</a
          >
          {{ disease.onset.ontologyClass.label }}
        </span>
        <span *ngIf="!disease.onset?.ontologyClass">
          {{ disease.onset?.toString() }}
        </span>
      </td>
      <td>{{ disease.resolution?.toString() }}</td>
      <td>
        <button
          pButton
          pRipple
          icon="pi pi-pencil"
          class="p-button-rounded p-button-text"
          (click)="editDisease(disease)"
        ></button>
        <button
          pButton
          pRipple
          icon="pi pi-trash"
          class="p-button-rounded p-button-text p-button-warning"
          (click)="deleteDisease(disease)"
        ></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-disease>
    <tr>
      <td colspan="7">
        <div class="p-3">
          <app-disease-detail [disease]="disease"> </app-disease-detail>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="7">No disease to display.</td>
    </tr>

    <tr></tr
  ></ng-template>
</p-table>
