<div class="row footer">
    <div class="col-md-12">
        <nav class="pull-left">
            <ul>
                <li>
                    <a [routerLink]="['/about', { selectedTab: 0 }]"> About </a>
                </li>
                <li>
                    <a
                            href="https://www.jax.org/"
                            rel="noopener noreferrer"
                            target="_blank"
                    >
                        The Jackson Laboratory
                    </a>
                </li>
                <li>
                    <a [routerLink]="['/about', { selectedTab: 4 }]"> License </a>
                </li>
                <li>
                    <div class="social-buttons">
                        <a
                                href="https://github.com/TheJacksonLaboratory/PhenopacketLab/"
                                target="_blank"
                        ><img src="/assets/img/GitHub-Mark-32px.png"
                        /></a>
                    </div>
                </li>
            </ul>
        </nav>
    </div>
</div>
<div class="row footer">
    <div class="col-md-12">
        <div class="copyright">
            <p>
                ©2023 THE JACKSON LABORATORY <br/>
                Website content is for educational and research purposes only and is
                not intended to be used for medical advice, diagnosis or treatment.
            </p>
        </div>
    </div>
</div>
