<div class="grid p-fluid">
  <div class="field col-12 md:col-4">
    <b>Created:</b>
    <br />
    {{ created }}
  </div>
  <div class="field col-12 md:col-4">
    <b>Created by:</b>
    <br />
    {{ createdBy }}
  </div>
  <div class="field col-12 md:col-4">
    <b>Submitted by:</b>
    <br />
    {{ submittedBy }}
  </div>
  <div class="field col-12">
    <b>Phenopacket schema version:</b> {{ phenopacketSchemaVersion }}
  </div>
  <div *ngIf="updates && updates.length > 0" class="field col-12">
    <ng-template pTemplate="caption"> Updates </ng-template>
    <p-table
      [value]="updates"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-sm"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Timestamp</th>
          <th>Updated by</th>
          <th>Comment</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-update>
        <tr>
          <td>{{ update.timestamp }}</td>
          <td>{{ update.updatedBy }}</td>
          <td>{{ update.comment }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div *ngIf="resources && resources.length > 0" class="field col-12">
    <p-table 
      [value]="resources"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-sm"
    >
      <ng-template pTemplate="caption"> Resources </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Version</th>
          <th>URL</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-resource>
        <tr>
          <td>{{ resource.id }}</td>
          <td>{{ resource.name }}</td>
          <td>{{ resource.version }}</td>
          <td>{{ resource.url }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div *ngIf="externalReferences && externalReferences.length > 0" class="field col-12">
    <p-table 
      [value]="externalReferences"
      [tableStyle]="{ 'min-width': '50rem' }"
      styleClass="p-datatable-sm"
    >
      <ng-template pTemplate="caption"> External references </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>ID</th>
          <th>Reference</th>
          <th>Description</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-externalreference>
        <tr>
          <td>{{ externalreference.id }}</td>
          <td>{{ externalreference.reference }}</td>
          <td>{{ externalreference.description }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
