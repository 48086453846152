<p-panel>
  <ng-template pTemplate="header">
    <span class="font-medium text-xl"><a href="{{ file?.uri }}" target="_blank">{{
      file?.uri
      }}</a></span>
  </ng-template>
  <div class="grid">
    <div class="field col-12"><b>URI:</b> {{ file?.uri }}</div>
    <div class="field col-12"><b>Description:</b> {{ description }}</div>
    <div *ngIf="mappings?.length > 0" class="field col-6">
      <p-table [value]="mappings" dataKey="key" styleClass="p-datatable-sm">
        <ng-template pTemplate="caption">
          <div class="table-header">Individual ID to File Identifier mapping</div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>Subject ID</th>
            <th>ID used in file</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-mapping>
          <tr>
            <td>{{ mapping.subjectId }}</td>
            <td>{{ mapping.fileId }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="2">No mapping to display.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div *ngIf="attributes?.length > 0" class="field col-6">
      <p-table [value]="attributes" dataKey="key" styleClass="p-datatable-sm">
        <ng-template pTemplate="caption">
          <div class="table-header">Attributes</div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>ID</th>
            <th>Value</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-attribute>
          <tr>
            <td>{{ attribute.label }}</td>
            <td>{{ attribute.value }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="2">No attribute to display.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-panel>
