<div class="grid p-fluid">
  <div class="field col-12">
    <label for="individualId"><b>Individual ID *</b></label>
    <input
      id="individualId"
      #id="ngModel"
      type="text"
      required
      pInputText
      [(ngModel)]="subject.id"
      [ngClass]="{
        'ng-dirty': (id?.invalid) || (id?.dirty && id?.invalid)
      }"
    />
    <small
      *ngIf="(id?.invalid) || (id?.dirty && id?.invalid)"
      class="p-error"
      >Individual ID is required.</small
    >
  </div>
  
  <!-- <div class="field col-12 md:col-6">
    <label for="alternateIds">Alternate IDs</label>
    <p-chips id="alternateIds" [(ngModel)]="subject.alternateIds"></p-chips>
  </div> -->
  <div class="field col-12 md:col-4">
    <label for="lastEncounterDate">Age at last clinical encounter</label>
    <app-time-element
      id="lastEncounterDate"
      [timeElement]="timeOfLastEncounter"
      (timeElementEvent)="updateTimeOfLastEncounter($event)"
    ></app-time-element>
  </div>

  <div class="field col-12 md:col-4">
    <label for="sex">Sex</label>
    <p-dropdown
      id="sex"
      appendTo="body"
      placeholder="Select sex"
      [(ngModel)]="selectedSex"
      [options]="getSexes()"
      (ngModelChange)="updateSex($event)"
      pTooltip="Observed apparent sex of the individual"
      tooltipPosition="bottom"
      showDelay="2000"
      class="w-full"
      optionLabel="lbl"
      [showClear]="true"
    >
    </p-dropdown>
  </div>

  <div class="field col-12 md:col-4">
    <label for="karyotypicSex">Karyotypic Sex</label>
    <p-dropdown
      id="karyotypicSex"
      appendTo="body"
      placeholder="Select karyotypic sex"
      [(ngModel)]="selectedKaryotypicSex"
      [options]="getKaryotypicSexes()"
      (ngModelChange)="updateKaryotypicSex($event)"
      pTooltip="The karyotypic sex of the individual"
      tooltipPosition="bottom"
      showDelay="2000"
      class="w-full"
      optionLabel="lbl"
      [showClear]="true"
    >
      <ng-template let-karyosex pTemplate="item">
        <div
          [pTooltip]="karyosex.definition"
          escape="false"
          tooltipPosition="bottom"
        >
          {{ karyosex.lbl }}
        </div>
      </ng-template>
    </p-dropdown>
  </div>
  <div class="field col-12 md:col-4"></div>
</div>
<h3><b>Vital Status</b></h3>
<div class="grid p-fluid">
  <div class="field col-12 md:col-4">
    <label for="status">Status</label>
    <p-dropdown
      id="status"
      appendTo="body"
      placeholder="Select status"
      [options]="getStatuses()"
      [(ngModel)]="status"
      (ngModelChange)="updateStatus($event)"
      pTooltip="The vital status of the individual e.g. whether they are alive or the time and cause of death. RECOMMENDED"
      tooltipPosition="bottom"
      showDelay="2000"
      class="w-full"
      [showClear]="true"
    ></p-dropdown>
  </div>
  <div class="field col-12 md:col-4"></div>
  <div class="field col-12 md:col-4"></div>

  <div class="field col-12 md:col-4" *ngIf="status === getDeceasedStatus()">
    <label for="survivalTime"
      >Survival time (days)
    </label>
    <div id="survivalTime" class="grid p-fluid">
      <div *ngIf="profile === undefined" class="field col-12 md:col-6">
        <p-inputNumber
          [ngModel]="subject?.vitalStatus?.survivalTimeInDays"
          (onInput)="updateSurvivalTime($event)"
          pTooltip="Number of days the patient was alive after their primary diagnosis"
          tooltipPosition="bottom"
        ></p-inputNumber>
      </div>
      <div *ngIf="profile" class="field col-12">
        <p-inputNumber
          [ngModel]="subject?.vitalStatus?.survivalTimeInDays"
          (onInput)="updateSurvivalTime($event)"
          pTooltip="Number of days the patient was alive after their primary diagnosis"
          tooltipPosition="bottom"
        ></p-inputNumber>
      </div>
      <div *ngIf="profile === undefined" class="field col-12 md:col-6">
        <div  class="flex align-items-center gap-1">
          <p-checkbox
            id="useCalendar"
            [(ngModel)]="useCalendar"
            [binary]="true"
          ></p-checkbox>
          <label for="useCalendar">Use calendar range</label>
        </div>
      </div>
      <div
        *ngIf="useCalendar && profile === undefined"
        class="field col-12"
      >
        <p-calendar
          #rangecalendar
          [(ngModel)]="rangeDates"
          (onSelect)="updateSurvivalTime($event)"
          selectionMode="range"
          [readonlyInput]="true"
          pTooltip="Select a range of days with the first day being the date of diagnosis and the second the day of death. The number of survival days will be automatically set given this range."
          tooltipPosition="bottom"
          [showIcon]="true"
        ></p-calendar>
      </div>
    </div>
  </div>
  <div class="field col-12 md:col-4" *ngIf="status === getDeceasedStatus()">
    <label for="timeOfDeath">Time of death</label>
    <app-time-element
      id="timeOfDeath"
      [timeElement]="subject?.vitalStatus?.timeOfDeath"
      (timeElementEvent)="updateTimeOfDeath($event)"
    ></app-time-element>
  </div>
  <div class="field col-12 md:col-4" *ngIf="status === getDeceasedStatus()">
    <label for="causeOfDeath">Cause of death</label>
    <p-dropdown
      id="causeOfDeath"
      placeholder="Search for a cause of death"
      appendTo="body"
      [options]="causeOfDeathItems"
      [(ngModel)]="selectedCauseOfDeath"
      filter="true"
      filterBy="id,label"
      (onFilter)="causeOfDeathContentChanging($event.filter)"
      (onChange)="updateCauseOfDeath($event.value)"
      showClear="true"
    >
      <ng-template let-cause pTemplate="item">
        <div
          [pTooltip]="cause.label"
          showDelay="1000"
          tooltipPosition="left"
          escape="false"
        >
          [{{ cause.id }}] {{ cause.label }}
        </div>
      </ng-template>
    </p-dropdown>
  </div>
</div>
<p-toast *ngIf="profile"></p-toast>
