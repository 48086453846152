<p-dropdown
  appendTo="body"
  [options]="timeElementTypes"
  [(ngModel)]="selectedAgeType"
  (onChange)="ageTypeChange($event)"
  placeholder="Select an age type"
  [showClear]="true"
></p-dropdown>

<div *ngIf="selectedAgeType === ageType">
  <app-age
    (ageEvent)="updateTimeElement($event)"
    [age]="age"
  ></app-age>
</div>

<div *ngIf="selectedAgeType === ageRangeType">
  <app-age-range
    (ageRangeChange)="updateTimeElement($event)"
    [ageRange]="ageRange"
  ></app-age-range>
</div>

<div *ngIf="selectedAgeType === gestationalAgeType">
  <app-gestational-age
    (gestationalAgeChange)="updateTimeElement($event)"
    [gestationalAge]="gestationalAge"
  ></app-gestational-age>
</div>

<div *ngIf="useOntologyClass && selectedAgeType === ontologyClassType">
  <app-ontology-time
    (ontologyClassChange)="updateTimeElement($event)"
    [ontologyClass]="ontologyClass"
    [ontologyNodes]="ontologyNodes"
  ></app-ontology-time>
</div>
<div *ngIf="selectedAgeType === timestampType">
  <div class="grid p-fluid mt-2">
    <div class="field col-12">
      <label for="timestamp">Timestamp</label>
      <input
        pInputText
        id="timestamp"
        type="text"
        placeholder="YYYY-MM-DDTHH:mm:ss. sssZ"
        [ngModel]="timestamp"
        (ngModelChange)="updateTimeElement($event)"
      />
      <!-- <p-inputMask id="timestamp" mask="YYYY-MM-DDTHH:mm:ss. sssZ" [(ngModel)]="timestamp" placeholder="yyyy-MM-dd'T'HH:mm:ss.SSS'Z'" (onInput)="updateTimeElement($event)"></p-inputMask> -->
    </div>
  </div>
</div>
