<div class="flex">
  <div class="flex-initial flex align-items-center justify-content-center">
    <button
      pButton
      icon="pi pi-plus"
      label="Add interpretation"
      class="p-button-outlined mb-2"
      (click)="addInterpretation()"
    ></button>
  </div>
</div>

<p-table *ngIf="interpretations?.length > 0" [value]="interpretations" dataKey="key" styleClass="p-datatable-sm">
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th>ID</th>
      <th>Status</th>
      <th>Diagnosis</th>
      <th>Summary</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-interpretation let-expanded="expanded">
    <tr>
      <td>
        <button
          type="button"
          pButton
          pRipple
          [pRowToggler]="interpretation"
          class="p-button-text p-button-rounded p-button-plain"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        ></button>
      </td>
      <td>{{ interpretation.id }}</td>
      <td>{{ interpretation.progressStatus }}</td>
      <td>{{ interpretation.diagnosis?.disease.toString() }}</td>
      <td>{{ interpretation.summary }}</td>
      <td>
        <button
          pButton
          pRipple
          icon="pi pi-pencil"
          class="p-button-rounded p-button-text"
          (click)="editInterpretation(interpretation)"
        ></button>
        <button
          pButton
          pRipple
          icon="pi pi-trash"
          class="p-button-rounded p-button-text p-button-warning"
          (click)="deleteInterpretation(interpretation)"
        ></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-interpretation>
    <tr>
      <td colspan="6">
        <div class="p-3">
          <app-interpretation-detail
            [interpretation]="interpretation"
          ></app-interpretation-detail>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="6">No interpretation to display.</td>
    </tr>

    <tr></tr
  ></ng-template>
</p-table>
