<div class="m-5">
  <h1 class="header-step font-light">Individual</h1>
  <h3 class="header-step">Add subject information and vital status</h3>
  <div class="grid">
    <div class="field col-12">
      <label for="phenoId"><b>Phenopacket ID *</b></label>
      <div class="p-inputgroup">
        <input
          id="phenoId"
          #id="ngModel"
          type="text"
          required
          pInputText
          [(ngModel)]="phenopacket.id"
          [ngClass]="{
            'ng-dirty': (id?.invalid) || (id?.dirty && id?.invalid)
          }"
        />
        <button
          pButton
          icon="pi pi-replay"
          label="Generate ID"
          (click)="generateNewID()"
        ></button>
      </div>
      <small
        *ngIf="(id?.invalid) || (id?.dirty && id?.invalid)"
        class="p-error"
        >Phenopacket ID is required.</small
      >
    </div>
    <div class="field col-12">
      <app-individual-edit
        [subject]="phenopacket?.subject"
        [profile]="profileSelection"
        [submitted]="submitted"
        (subjectChange)="updateSubject($event)"
      ></app-individual-edit>
    </div>
  </div>
</div>
