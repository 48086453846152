<p-autoComplete
  appendTo="body"
  [(ngModel)]="selectedItem"
  [suggestions]="groupedItems"
  (completeMethod)="contentChanging($event.query)"
  (onSelect)="submitQuery($event)"
  field="name"
  [group]="true"
  [showClear]="true"
  placeholder="{{ placeHolderTxt }}"
>
  <ng-template let-group pTemplate="group">
    <div class="flex align-items-center">
      {{ group.label }}
    </div>
  </ng-template>
</p-autoComplete>
