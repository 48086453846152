<p-chips *ngIf="selectedNodes?.length > 0"
  ngDefaultControl
  [max]="selectedNodes?.length || 0"
  [(ngModel)]="selectedNodes"
  [allowDuplicate]="false"
  [addOnTab]="true"
  [addOnBlur]="true"
  [disabled]="true"
  ><ng-template let-item pTemplate="item">
    {{ item?.label }}
  </ng-template>
</p-chips>
<p-tree
  ngDefaultControl
  [value]="nodes"
  placeholder="Select Item"
  [filter]="true"
  filterBy="label,key"
  [(selection)]="selectedNodes"
>
  <!-- <div *ngIf="selectionMode === 'tricheckbox'">
    <ng-template let-node pTemplate="default">
      <p-triStateCheckbox
        (onChange)="nodeSelectionChange(node)"
        [(ngModel)]="node.state"
      ></p-triStateCheckbox>

      [{{ node.key }}] {{ node.label }}
    </ng-template>
  </div> -->
  <ng-template let-node pTemplate="default">
    <p-checkbox *ngIf="node.selectable === true || node.selectable === undefined" [(ngModel)]="node.isSelected" (onChange)="selectionChange($event.checked, node)" [binary]="true">
          </p-checkbox>
    <span [pTooltip]="node.label" showDelay="2000" tooltipPosition="bottom">[{{ node.key }}] {{ node.label }}</span>
  </ng-template>
</p-tree>
<!-- <p-accordion>
  <p-accordionTab header="Horizontal tree view">
    <p-tree
      ngDefaultControl
      [(ngModel)]="selectedNodes"
      [value]="nodes"
      layout="horizontal"
      [selectionMode]="selectionMode"
      [filter]="true"
      [propagateSelectionDown]="false"
      [propagateSelectionUp]="false"
      (onNodeSelect)="nodeSelect($event)"
      (onNodeUnselect)="nodeUnselect($event)"
    ></p-tree>
  </p-accordionTab>
</p-accordion> -->
