<div class="flex">
  <div class="flex-initial flex align-items-center justify-content-center">
    <button
      pButton
      icon="pi pi-plus"
      label="Add phenotypic feature"
      class="p-button-outlined mb-2"
      (click)="addPhenotypicFeature()"
    ></button>
  </div>
</div>

<p-table *ngIf="phenotypicFeatures?.length > 0" [value]="phenotypicFeatures" dataKey="key" styleClass="p-datatable-sm">
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th>Label</th>
      <th>Status</th>
      <th>Onset</th>
      <th>Resolution</th>
      <th>Severity</th>
      <th>Modifiers</th>
      <th>Evidence</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-feature let-expanded="expanded">
    <tr>
      <td>
        <button
          type="button"
          pButton
          pRipple
          [pRowToggler]="feature"
          class="p-button-text p-button-rounded p-button-plain"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        ></button>
      </td>
      <td>{{ feature.type.label }}</td>
      <td>{{ feature.excluded ? "Excluded" : "Observed" }}</td>
      <td>
        <span *ngIf="feature.onset?.ontologyClass"
          ><a [href]="feature.onset.ontologyClass.termUrl" target="_blank"
            >[{{ feature.onset.ontologyClass.id }}]</a
          >
          {{ feature.onset.ontologyClass.label }}</span
        ><span *ngIf="!feature.onset?.ontologyClass">{{
          feature.onset?.toString()
        }}</span>
      </td>
      <td>{{ feature.resolution?.toString() }}</td>
      <td>
        <a
          *ngIf="feature.severity"
          [href]="feature.severity?.termUrl"
          target="_blank"
          >[{{ feature.severity?.id }}]</a
        >
        {{ feature.severity?.label }}
      </td>
      <td>
        <span
          *ngFor="let modifier of feature.modifiers; let indexOfelement = index"
        >
          <a [href]="modifier.termUrl" target="_blank">[{{ modifier.id }}]</a>
          {{ modifier.label
          }}<span *ngIf="indexOfelement < feature.modifiers?.length - 1"
            >,
          </span>
        </span>
      </td>
      <td>
        <span
          *ngFor="let evidence of feature.evidence; let indexOfelement = index"
        >
          <a [href]="evidence.evidenceCode?.termUrl" target="_blank"
            >[{{ evidence.evidenceCode?.id }}]</a
          >
          {{ evidence.evidenceCode?.label
          }}<span *ngIf="indexOfelement < feature.evidence?.length - 1"
            >,
          </span>
        </span>
      </td>
      <td>
        <button
          pButton
          pRipple
          icon="pi pi-pencil"
          class="p-button-rounded p-button-text"
          (click)="editPhenotypicFeature(feature)"
        ></button>
        <button
          pButton
          pRipple
          icon="pi pi-trash"
          class="p-button-rounded p-button-text p-button-warning"
          (click)="deleteFeature(feature)"
        ></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-feature>
    <tr>
      <td colspan="9">
        <div class="p-3">
          <app-phenotypic-detail
            [phenotypicFeature]="feature"
          ></app-phenotypic-detail>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="9">No phenotypic feature to display.</td>
    </tr>
  </ng-template>
</p-table>
