<span *ngIf="biosamples?.length === 0 || !biosamples">No biosample to display</span>
<p-table *ngIf="biosamples?.length > 0" [value]="biosamples" dataKey="key" styleClass="p-datatable-sm">
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th>ID</th>
      <th>Individual ID</th>
      <th>Sampled tissue</th>
      <th>Type</th>
      <th>Description</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-sample let-expanded="expanded">
    <tr>
      <td>
        <button
          type="button"
          pButton
          pRipple
          [pRowToggler]="sample"
          class="p-button-text p-button-rounded p-button-plain"
          [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
        ></button>
      </td>
      <td>{{ sample.id }}</td>
      <td>{{ sample.individualId }}</td>
      <td>{{ sample.sampledTissue?.toString() }}</td>
      <td>{{ sample.sampleType?.toString() }}</td>
      <td>{{ sample.description }}</td>
      <td>
        <!-- <button
                pButton
                pRipple
                icon="pi pi-pencil"
                class="p-button-rounded p-button-text"
                (click)="editBiosample(sample)"
              ></button> -->
        <button
          pButton
          pRipple
          icon="pi pi-trash"
          class="p-button-rounded p-button-text p-button-warning"
          (click)="deleteSample(sample)"
        ></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-sample>
    <tr>
      <td colspan="7">
        <div class="p-3">
          <app-biosample-detail [biosample]="sample"> </app-biosample-detail>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="7">No biosample to display.</td>
    </tr>

    <tr></tr
  ></ng-template>
</p-table>
