<p-toast position="center" key="cen"></p-toast>
<div class="grid p-fluid">
  <div class="field col-12 center">
    <b>Enter variant data</b>
  </div>
  <div class="field col-12 md:col-4">
    <span class="p-float-label">
      <input pInputText id="hgvs" [(ngModel)]="hgvs" />
      <label htmlFor="hgvs">Variant description</label>
    </span>
    E.g.
    <a href="javascript:void(0);" (click)="updateHgvs('NM_000088.3:c.589G>T')"
      >NM_000088.3:c.589G>T</a
    >
  </div>
  <div class="field col-12 md:col-4">
    <button
      id="button"
      pButton
      type="button"
      style="width: 200px"
      label="Validate variant"
      (click)="searchVariantByHGVS()"
    ></button>
  </div>
  <div class="field col-12 md:col-4"></div>
  <div class="field col-12">
    <p-accordion>
      <p-accordionTab header="Variant description examples">
        <div class="grid p-fluid">
          <div class="field col-12">
            <p>
              Input examples for the 'Variant Description' field are the
              following:
            </p>
          </div>
          
          <div class="field col-12 md:col-4">
            <i>HGVS</i>
            <br />
            <ul>
              <li>NM_000088.3:c.589G>T</li>
              <li>NC_000017.10:g.48275363C>A</li>
              <li>NG_007400.1:g.8638G>T</li>
              <li>LRG_1:g.8638G>T</li>
              <li>LRG_1t1:c.589G>T</li>
            </ul>
          </div>
          <div class="field col-12 md:col-4">
            <i>Pseudo-VCF</i>
            <ul>
              <li>17-50198002-C-A</li>
              <li>17:50198002:C:A</li>
              <li>GRCh38-17-50198002-C-A</li>
              <li>GRCh38:17:50198002:C:A</li>
            </ul>
          </div>
          <div class="field col-12 md:col-4">
            <i>Hybrid</i>
            <ul>
              <li>chr17:50198002C>A</li>
              <li>chr17:50198002C>A(GRCh38)</li>
              <li>chr17(GRCh38):50198002C>A</li>
              <li>chr17:g.50198002C>A</li>
              <li>chr17:g.50198002C>A(GRCh38)</li>
              <li>chr17(GRCh38):g.50198002C>A</li>
            </ul>
          </div>
        </div>
        <div class="field col-12">
          <p>
            This interface uses the Variant Validator
            <b
              ><a href="https://rest.variantvalidator.org/" target="_blank"
                >API</a
              ></b
            >
            in order to find and create new variant interpretation in the
            phenopacket. Build GRCh38 is used in this UI to perform a
            validation. In order to perform more detailed validations, you can
            directly use the PhenopacketLab API accessible on this
            <a [href]="apiLink" target="_blank">link</a>.
          </p>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
</div>
