<p-toast position="center" key="cen"></p-toast>
<p-confirmDialog
  position="center"
  [style]="{ width: '50vw' }"
></p-confirmDialog>
<div class="grid p-fluid">

  <div *ngIf="genomicInterpretation.variantInterpretation" class="field col-12 md:col-6">
    <label for="interpretationstatus">Interpretation Status *</label>
    <p-dropdown
      id="interpretationstatus"
      appendTo="body"
      placeholder="Select interpretation status"
      [ngModel]="genomicInterpretation?.interpretationStatus"
      [options]="interpretationStatuses"
      required
      (onChange)="updateInterpretationStatus($event.value)"
      pTooltip="Interpretation status"
      tooltipPosition="bottom"
      showDelay="2000"
      scrollHeight="20rem"
      class="w-full"
      showClear="true"
      [ngClass]="{
        'ng-dirty':
          !genomicInterpretation?.interpretationStatus && submitted
      }"
    >
    </p-dropdown>
    <small *ngIf="!genomicInterpretation?.interpretationStatus && submitted" class="p-error"
      >Interpretation status is required.</small
    >
  </div>

  <app-variant-search-interpretation
    [variantInterpretation]="genomicInterpretation?.variantInterpretation"
    [profile]="profile"
    [submitted]="submitted"
    (variantInterpretationChange)="addVariantInterpretation($event)"
  ></app-variant-search-interpretation>
</div>
<br>
<div *ngIf="genomicInterpretation?.variantInterpretation" class="p-dialog-footer">
  <button
    pButton
    pRipple
    type="button"
    label="Cancel"
    (click)="onCancelClick()"
  ></button>
  <button
    pButton
    pRipple
    type="button"
    label="OK"
    (click)="onOkClick()"
  ></button>
</div>
