<div class="sidebar">
    <div class="sidebar-title p-element menu-list">
        <div class="p-menu">
            <div class="p-element p-menuitem">
                <a class="p-menuitem-text" href="/">
                    Phenopackets
                </a>
                <a class="p-menuitem-icon pi pi-fw pi-plus" href="/creator/rare">
                </a>
            </div>
        </div>
    </div>
</div>
<!--<p-menu [model]="items" class="menu-list phenopacket-menu"></p-menu>-->
