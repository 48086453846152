<div class="help static-content-profile grid">
  <div class="wrapper col-8 col-offset-2">
    <div class="subsection">
      <h2 class="subsection-title">Frequently Asked Questions</h2>
      <div class="p-accordion-sm">
        <p-accordion>
          <p-accordionTab
            header="Where can I find the PhenopacketLab documentation?"
          >
            The PhenopacketLab documentation with walkthroughs and how-tos can
            be found
            <a [href]="docsUrl" target="_blank" class=""
              >here <i class="pi pi-fw pi-external-link"></i></a
            >.
          </p-accordionTab>
          <p-accordionTab
            header="Do phenopackets contain confidential information?"
          >
            Phenopackets can contain confidential information in the form of
            name, date of birth, and genomic information.
          </p-accordionTab>
          <p-accordionTab header="What other ways can I create phenopackets?">
            Phenopackets can be created programmatically through our Java and
            Python API's. Maven
            <div class="code-highlight">
              <pre>
                                &#60;dependency&#62;
                                &#60;groupId&#62;org.phenopackets&#60;/groupId&#62;
                                &#60;artifactId&#62;phenopacket-schema&#60;/artifactId&#62;
                                &#60;version&#62;${{
                  "{"
                }}phenopacket-schema.version{{ "}" }}&#60;/version&#62;
                                &#60;/dependency&#62;
                            </pre
              >
            </div>
            Gradle
            <div class="code-highlight">
              <pre>
                                implementation 'org.phenopackets:phenopacket-schema'
                            </pre
              >
            </div>
            Pip
            <div class="code-highlight">
              <pre>
                                pip install phenopackets
                            </pre
              >
            </div>
          </p-accordionTab>
          <p-accordionTab header="What can I do with my phenopackets?">
            <p>
              The Phenopacket Schema is an emerging GLobal Alliance for Genomics
              and Health (GA4GH) standard for sharing disease and phenotype
              information that characterizes an individual person, linking that
              individual to detailed phenotypic descriptions, genetic
              information, diagnoses, and treatments.
            </p>
            <p>
              The Phenopacket Schema, together with other GA4GH data and
              technical standards, will enable data exchange and provide a
              foundation for the computational analysis of disease and phenotype
              information to improve our ability to diagnose and conduct
              research on all types of disorders, including cancer and rare
              diseases. Specifically, Phenopackets are designed to be both human
              and machine‐interpretable, enabling computing operations and
              validation on the basis of defined relationships between
              diagnoses, lab measurements, and genotypic information.
            </p>
            <p>
              Phenopackets can be used for a growing number of computational
              tasks, including serving as input for genomic analysis software
              such as such as
              <a
                href="https://exomiser.readthedocs.io/en/stable"
                target="_blank"
                >Exomiser</a
              >,
              <a
                href="https://thejacksonlaboratory.github.io/LIRICAL/stable"
                target="_blank"
                >LIRICAL</a
              >,
              <a href="https://svanna.readthedocs.io/en/master" target="_blank"
                >SvAnna</a
              >.
            </p>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
  </div>
</div>
