<div class="grid" *ngIf="phenopacket">
  <div class="field col-12"><b>Phenopacket ID: </b>{{ phenopacket.id }}</div>
  <div class="field col-12">
      <p-fieldset legend="General">
        <div class="grid">
          <div class="field col-5">
            <b>Individual ID: </b> {{ phenopacket?.subject?.id }}
          </div>
          <div class="field col-6">
            <b> Alternate IDs: </b> {{ phenopacket?.subject.alternateIds?.join(", ") }}
          </div>
          <div class="field cold-1">
            <button
              pButton
              pRipple
              icon="pi pi-pencil"
              pTooltip="Edit individual properties"
              tooltipPosition="bottom"
              class="p-button-rounded p-button-text"
              (click)="openEditDialog()"
            ></button>
          </div>
          <div class="field col-12">
            <b>Time of last encounter: </b> {{ phenopacket?.subject?.timeAtLastEncounter ? phenopacket?.subject?.timeAtLastEncounter.toString() : '' }}
          </div>

          <div class="field col-4"><b>Sex: </b> {{ phenopacket?.subject?.sex }}</div>

          <div class="field col-4">
            <b>Karyotypic Sex: </b> {{ phenopacket?.subject?.karyotypicSex }}
          </div>
          <div class="field col-4"><b>Gender: </b> {{ phenopacket?.subject?.gender }}</div>
        </div>
      </p-fieldset>
        <br />
      <p-fieldset legend="Vital Status">
            <div class="grid">
              <div class="field col-6">
                <b>Status: </b> {{ phenopacket?.subject?.vitalStatus?.status?.toString() }}
              </div>
              <div class="field col-6">
                <b>Time of death: </b> {{ phenopacket?.subject?.vitalStatus?.timeOfDeath?.toString() }}
              </div>
              <div class="field col-6">
                <b>Cause of death: </b>
                <a
                        *ngIf="phenopacket?.subject?.vitalStatus?.causeOfDeath"
                        [href]="phenopacket?.subject?.vitalStatus?.causeOfDeath?.termUrl"
                        target="_blank"
                >[{{ phenopacket?.subject?.vitalStatus?.causeOfDeath?.id }}]</a
                >
                {{ phenopacket?.subject?.vitalStatus?.causeOfDeath?.label }}
              </div>
              <div class="field col-6">
                <b>Survival time (days): </b>  {{ phenopacket?.subject?.vitalStatus?.survivalTimeInDays }}
              </div>
            </div>
          </p-fieldset>
  </div>
  <br />
  <div class="field col-12">
    <p-accordion class="w-full">
      <p-accordionTab>
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <!-- <i class="pi pi-calendar mr-2"></i> -->
            <span class="material-icons tab-icon mr-2">list</span>
            <span class="vertical-align-middle">Phenotypic Features</span>
            <span class="ml-3 font-light text-sm"
              >Describes a phenotype that characterizes the subject of the
              Phenopacket</span
            >
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <app-phenotypic-feature
            [phenotypicFeatures]="phenopacket?.phenotypicFeatures"
            (onPhenotypicFeaturesChanged)="changePhenotypicFeatures($event)"
          ></app-phenotypic-feature>
        </ng-template>
      </p-accordionTab>
      <p-accordionTab [disabled]="phenopacket?.measurements === undefined">
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <span class="material-icons tab-icon mr-2">square_foot</span>
            <span class="vertical-align-middle">Measurements</span>
            <span class="ml-3 font-light text-sm"
              >Used to record individual measurements, which can capture
              quantitative, ordinal (e.g., absent/present), or categorical
              measurements</span
            >
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <app-measurement
            [measurements]="phenopacket?.measurements"
            (onMeasurementsChanged)="changeMeasurements($event)"
          ></app-measurement>
        </ng-template>
      </p-accordionTab>
      <p-accordionTab [disabled]="phenopacket?.biosamples === undefined">
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <span class="material-icons tab-icon mr-2">biotech</span>
            <span class="vertical-align-middle">Biosamples</span>
            <span class="ml-3 font-light text-sm"
              >Unit of biological material from which the substrate molecules
              (e.g. genomic DNA, RNA, proteins) for molecular analyses (e.g.
              sequencing, array hybridisation, mass-spectrometry) are
              extracted</span
            >
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <app-biosample
            [biosamples]="phenopacket?.biosamples"
            (onBiosampleChanged)="changeBiosamples($event)"
          ></app-biosample>
        </ng-template>
      </p-accordionTab>
      <p-accordionTab>
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <span class="material-icons tab-icon mr-2">interpreter_mode</span>
            <span class="vertical-align-middle">Interpretations</span>
            <span class="ml-3 font-light text-sm"
              >Represents the interpretation of a genomic analysis, such as the
              report from a diagnostic laboratory</span
            >
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <app-interpretation
            [interpretations]="phenopacket?.interpretations"
            [phenopacket]="phenopacket"
            (onInterpretationsChange)="changeInterpretations($event)"
          ></app-interpretation>
        </ng-template>
      </p-accordionTab>
      <p-accordionTab>
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <span class="material-icons tab-icon mr-2">coronavirus</span>
            <span class="vertical-align-middle">Diseases</span>
            <span class="ml-3 font-light text-sm"
              >Diagnosis, i.e., an inference or hypothesis about the cause
              underlying the observed phenotypic abnormalities</span
            >
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <app-disease
            [diseases]="phenopacket?.diseases"
            (onDiseasesChanged)="changeDiseases($event)"
          ></app-disease>
        </ng-template>
      </p-accordionTab>
      <p-accordionTab>
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <span class="material-icons tab-icon mr-2">medical_services</span>
            <span class="vertical-align-middle">Medical Actions</span>
            <span class="ml-3 font-light text-sm"
              >Describes medications, procedures, other actions taken for
              clinical management</span
            >
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <app-medical-action
            [medicalActions]="phenopacket?.medicalActions"
            [diseases]="phenopacket?.diseases"
            [phenopacket]="phenopacket"
            (onMedicalActionChanged)="changeMedicalActions($event)"
          ></app-medical-action>
        </ng-template>
      </p-accordionTab>
      <p-accordionTab>
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <span class="material-icons tab-icon mr-2">text_snippet</span>
            <span class="vertical-align-middle">Files</span>
            <span class="ml-3 font-light text-sm"
              >External files linked with the structured phenotypic data, which
              can be used to inform analyses</span
            >
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <app-file
            [phenopacket]="phenopacket"
            [files]="phenopacket?.files"
            (onFilesChanged)="changeFiles($event)"
          >
          </app-file>
        </ng-template>
      </p-accordionTab>
      <p-accordionTab>
        <ng-template pTemplate="header">
          <div class="flex align-items-center">
            <span class="material-icons tab-icon mr-2">data_object</span>
            <span class="vertical-align-middle">Metadata</span>
            <span class="ml-3 font-light text-sm"
              >Structured definitions of the resources and ontologies used
              within the phenopacket</span
            >
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <app-metadata [metadata]="phenopacket?.metaData"></app-metadata>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </div>
</div>
<p-confirmDialog header="Confirmation"></p-confirmDialog>
