<p-toast position="center" key="cen"></p-toast>
<app-individual-edit [subject]="subject" (subjectChange)="updateSubject($event)"></app-individual-edit>

<div class="p-dialog-footer">
  <button
    pButton
    pRipple
    type="button"
    label="Cancel"
    (click)="onCancelClick()"
  ></button>
  <button
    pButton
    pRipple
    type="button"
    label="Ok"
    (click)="onOkClick()"
  ></button>
</div>
