<p-card header="Phenopacket UI creator/editor">
  <div class="grid p-fluid">
    <div class="field col-12">
      <p>
        The Phenopacket Lab application is meant to be a user interface to
        upload, edit and save Phenopackets. Phenopackets are JSON or YAML files
        with a structure that follows the
        <a
          href="https://phenopacket-schema.readthedocs.io/en/master/basics.html"
          target="_blank"
          >Phenopacket Schema</a
        >
        standard. It is an open standard for sharing disease and phenotype
        information to improve our ability to understand, diagnose and treat
        both rare and common diseases.
      </p>

      <p>
        This application is still under development and allows currently only
        the upload of JSON phenopacket files. Future features will include
        Phenopacket saving, export and search. A user authentication will also
        be implemented in order to enable users to save their phenopackets on
        the website. Finally a Phenopacket wizard creation is being implemented
        to ease the process of creating a Phenopacket from scratch.
      </p>
    </div>
  </div>
</p-card>
