<div class="grid m-5">
  <div class="field flex-grow-1">
    <div class="flex flex-wrap mb-5 justify-content-between">
      <div class="flex align-items-center justify-content-center">
        <h1 class="header-step">
          <b>Phenopacket Creator</b>
        </h1>
      </div>

      <div class="flex align-items-center justify-content-center">
        <p-toggleButton
          [(ngModel)]="rareProfileSelected"
          pTooltip="Toggle between Rare Disease steps and All available steps"
          tooltipPosition="bottom"
          [onLabel]="'Show all available steps'"
          [offLabel]="'Show rare disease steps'"
          (onChange)="updateProfile($event.checked)"
          styleClass="text-sm"
        ></p-toggleButton>
      </div>
    </div>

    <p-steps
      [model]="steps"
      [readonly]="false"
      [(activeIndex)]="rareProfileSelected === true ? activeIndexRare : activeIndexAll"
      (activeIndexChange)="onActiveIndexChange($event)"
    ></p-steps>
  </div>
</div>
<div class="flex flex-1">
  <router-outlet></router-outlet>
</div>
<div class="flex align-content-end justify-content-between m-5">
  <div class="flex align-items-center justify-content-center">
    <span *ngIf="rareProfileSelected === true ? activeIndexRare > 0 : activeIndexAll > 0">
      <p-button
        label="Back"
        (onClick)="prevPage()"
        icon="pi pi-angle-left"
        iconPos="left"
      ></p-button>
    </span>
  </div>
  <div class="flex align-items-center justify-content-center">
    <span *ngIf="rareProfileSelected === true ? activeIndexRare < steps.length - 1 : activeIndexAll < steps.length - 1">
      <p-button
        label="Next"
        (onClick)="nextPage()"
        icon="pi pi-angle-right"
        iconPos="right"
      ></p-button>
    </span>
    <span *ngIf="rareProfileSelected === true ? activeIndexRare == steps.length - 1 : activeIndexAll == steps.length - 1">
      <p-button
              label="Complete"
              (onClick)="complete()"
              icon="pi pi-check"
      ></p-button>
    </span>
  </div>
</div>
<p-confirmDialog header="Confirmation" width="425"></p-confirmDialog>
