<div class="grid p-fluid mt-2">
  <div class="field col-12">
    <label for="ontologyClass">Onset ontology terms</label>
    <p-treeSelect
      ngDefaultControl
      id="ontologyClass"
      appendTo="body"
      [(ngModel)]="ontologyClass"
      [options]="ontologyNodes"
      (onNodeSelect)="updateOntologyClass($event)"
      [metaKeySelection]="false"
      placeholder="Select term"
      [filter]="true"
      filterBy="label,key"
      [filterInputAutoFocus]="true"
    >
      <ng-template let-node pTemplate="default">
        <span [pTooltip]="node.label" showDelay="2000" tooltipPosition="bottom"
          >[{{ node.key }}] {{ node.label }}</span
        >
      </ng-template>
    </p-treeSelect>
  </div>
</div>
